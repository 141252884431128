import axios, { AxiosResponse } from 'axios'
import {
  IServerSideDatasource,
  IServerSideGetRowsParams
} from '@ag-grid-community/core'
import { ref } from 'vue'
import { ApiResponse, RowData, GridView, SortModelItem } from '../types'
import { createRansackQuery } from './ransackHelper'

interface Query {
  g: any[]
  s: string[]
  q: string
}

type DefaultQuery = {
  [key: string]: any
}

function buildtQuery(sortModel: SortModelItem[], defaultQuery: DefaultQuery) {
  return sortModel.map(sort => {
    return `${sort.sortableField} ${sort.sort}`
  })
}

export function buildApiRequestBody(
  params: IServerSideGetRowsParams | { request: any },
  selectedGridView: GridView,
  defaultQuery: DefaultQuery
) {
  const { request } = params || { request: {} }
  const { startRow = 0, endRow = 10 } = request

  const defaultQueryArray = Object.keys(defaultQuery).map(key => {
    return {
      [key]: defaultQuery[key]
    }
  }, {})

  const searchFields = selectedGridView.searchFields || []
  const filterFields = selectedGridView.filterFields || {}
  const filterFieldFilters = Object.keys(filterFields).map(field => {
    const filterField = filterFields[field]
    const query = createRansackQuery(filterField, field)
    return query
  })

  const searchFilters = searchFields
    .map(field => {
      if (!field.type) return null
      if (field.type === 'select' && !field.options) {
        return null
      }
      if (
        field.type === 'select' &&
        field.options &&
        field.options.length > 0 &&
        field.value
      ) {
        const regex = new RegExp(field.value.toString(), 'i')
        const fieldValues = field.options.filter(option =>
          regex.test(option.value?.toString())
        )

        if (fieldValues && fieldValues.length > 0) {
          const qurySearch = createRansackQuery(
            {
              filter: fieldValues.map((fieldValue: any) => {
                return fieldValue.id.toString()
              }),
              filterType: 'select',
              type: 'in',
              isSelectMultipleOptions: field.isSelectMultipleOptions || false
            },
            field.field
          )
          return qurySearch
        } else {
          return null
        }
      } else {
        return {
          [`${field.field}_cont`]: field.value
        }
      }
    })
    .filter(filter => filter !== null)

  let query: Query = {
    g: defaultQueryArray,
    s: [],
    q: ''
  }

  if (filterFieldFilters.length > 0 || searchFilters.length > 0) {
    query.g = [...query.g, ...filterFieldFilters]
    if (searchFilters.length > 0) {
      query.g.push({
        m: 'or',
        g: searchFilters
      })
    }
  }
  if (searchFilters.length === 0 && searchFields.length > 0) {
  }

  if (selectedGridView.sortModel) {
    const sort = selectedGridView.sortModel
      ? buildtQuery(selectedGridView.sortModel, defaultQuery)
      : ['name asc']

    query.s = sort
  }

  const additionalColumns = selectedGridView?.columns
    ?.filter(column => !column.isCustom)
    .map(column => {
      return column.colId || column.field
    })

  return {
    index: startRow,
    totalCount: endRow - startRow,
    meta: true,
    query: query,
    additionalColumns: additionalColumns
  }
}

export function useGridApi(apiName: string, defaultQuery: DefaultQuery) {
  const metaData = ref<any>({})
  const gridMetaData = ref<any[]>([])
  const getDataSource = (selectedGridView: GridView): IServerSideDatasource => {
    return {
      getRows: (params: IServerSideGetRowsParams) =>
        getRows(params, selectedGridView)
    }
  }

  const getRows = async (
    params: IServerSideGetRowsParams,
    selectedGridView: GridView
  ) => {
    try {
      const { request } = params
      const { startRow = 0, endRow = 10 } = request
      const pageSize = endRow - startRow

      if (pageSize > 100) {
        await processLargeRequest(params, selectedGridView, pageSize)
      } else {
        await processNormalRequest(params, selectedGridView)
      }
    } catch (error) {
      params.fail()
      console.error('Error fetching rows:', error)
    }
  }

  const processLargeRequest = async (
    params: IServerSideGetRowsParams,
    selectedGridView: GridView,
    totalSize: number
  ) => {
    const chunkSize = 50
    const { startRow = 0 } = params.request
    let allData: RowData[] = []
    let currentStartRow = startRow
    let lastRow: number | undefined = undefined
    let metaDataColumns: any[] = []

    while (currentStartRow < startRow + totalSize) {
      const chunkEndRow = Math.min(
        currentStartRow + chunkSize,
        startRow + totalSize
      )
      const chunkParams = {
        ...params,
        request: {
          ...params.request,
          startRow: currentStartRow,
          endRow: chunkEndRow
        }
      }

      const requestBody = buildApiRequestBody(
        chunkParams,
        selectedGridView,
        defaultQuery
      )
      const response: AxiosResponse<ApiResponse> = await axios.post(
        `/${apiName}/grid`,
        requestBody
      )

      const chunkData = response.data.data as RowData[]
      allData = [...allData, ...chunkData]

      lastRow = response.data.meta?.recordsFiltered

      currentStartRow = chunkEndRow

      if (metaDataColumns.length === 0) {
        metaDataColumns = response.data.meta?.columns || []
      }

      await new Promise(resolve => setTimeout(resolve, 100))
    }

    params.success({ rowData: allData, rowCount: lastRow || -1 })

    metaData.value = {
      columns: metaDataColumns,
      rowCount: lastRow || -1
    }
  }

  const processNormalRequest = async (
    params: IServerSideGetRowsParams,
    selectedGridView: GridView
  ) => {
    const requestBody = buildApiRequestBody(
      params,
      selectedGridView,
      defaultQuery
    )
    const response: AxiosResponse<ApiResponse> = await axios.post(
      `/${apiName}/grid`,
      requestBody
    )

    const data = response.data.data as RowData[]
    const metaDataColumns = response.data.meta?.columns || []
    const lastRow = response.data.meta?.recordsFiltered

    if (data.length === 0) {
      params.success({ rowData: data, rowCount: 0 })
      metaData.value = {
        columns: metaDataColumns,
        rowCount: 0
      }
      return
    }

    params.success({ rowData: data, rowCount: lastRow || -1 })

    metaData.value = {
      columns: metaDataColumns,
      rowCount: lastRow || -1
    }
  }
  return {
    getDataSource,
    getRows,
    metaData
  }
}
