// utils/gridVersionAdapter.ts

import {
  GridView,
  GridViewData,
  FilterField,
  SingleFilterField,
  CompositeFilterField,
  ConditionalFormatRule
} from '../types'

import moment from 'moment'

type FilterOptions = {
  filterType: 'options'
  selectedOptions: number[] | boolean[]
  isAllButBlanks: boolean
  isSelectMultipleOptions: boolean
}

const FILTER_TYPE_MAP = {
  equals: 'equals',
  notEqual: 'notEquals',
  lessThan: 'lessThan',
  lessThanOrEqual: 'lessThanOrEquals',
  greaterThan: 'greaterThan',
  greaterThanOrEqual: 'greaterThanOrEquals',
  inRange: 'between',
  blank: 'empty',
  notBlank: 'notEmpty'
}

function isVersion1Data(data: GridViewData) {
  const isFilter = 'globalSearchText' in data
  return isFilter
}

function isSingleFilterField(filter: FilterField): filter is SingleFilterField {
  return 'filter' in filter
}

function isCompositeFilterField(
  filter: FilterField
): filter is CompositeFilterField {
  return 'condition1' in filter && 'condition2' in filter
}

function isValidFilterValue(
  value: unknown
): value is string | string[] | number | boolean {
  return (
    typeof value === 'string' ||
    Array.isArray(value) ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
}

export function convertFilterModelToV2(
  filterModel: Record<string, any>
): Record<string, FilterField> {
  const filterFields: Record<string, FilterField> = {}

  for (const [key, filterField] of Object.entries(filterModel)) {
    console.log('filterField:', filterField)
    if (filterField.filterType === 'number') {
      if (filterField.operator) {
        filterFields[key] = {
          filterType: 'number',
          operator: filterField.operator,
          condition1: convertSingleNumberFilter(filterField.condition1),
          condition2: convertSingleNumberFilter(filterField.condition2)
        } as CompositeFilterField
      } else {
        filterFields[key] = convertSingleNumberFilter(filterField)
      }
    } else if (filterField.filterType === 'date') {
      filterFields[key] = convertDateFilter(filterField)
    } else if (filterField.filterType === 'text') {
      filterFields[key] = convertTextFilter(filterField)
    } else if (
      filterField.filterType === 'select' ||
      filterField.filterType === 'options'
    ) {
      filterFields[key] = convertSelectFilter(filterField)
    }
  }

  return filterFields
}

function convertSingleNumberFilter(filter: any): SingleFilterField {
  const type = FILTER_TYPE_MAP[filter.type] || filter.type
  let convertedFilter: SingleFilterField = {
    filterType: 'number',
    type: type,
    filter: filter.filter
  }

  if (type === 'between') {
    convertedFilter.filter = [filter.filter, filter.filterTo]
  } else if (type === 'empty' || type === 'not_empty') {
    convertedFilter.filter = null
  }

  return convertedFilter
}

function convertDateFilter(filter: any): SingleFilterField {
  console.log('filter:', filter)
  const type = FILTER_TYPE_MAP[filter.selectedSearch] || filter.selectedSearch
  const isEmpty = filter.preDefinedValue === 'EMPTY'
  const dateFrom = moment(filter.dateFrom).format('YYYY-MM-DD')
  const dateTo = moment(filter.dateTo).format('YYYY-MM-DD')
  return {
    filterType: 'date',
    type: type,
    filter:
      filter.dateFrom && filter.dateTo
        ? [filter.dateFrom, filter.dateTo]
        : filter.dateFrom || filter.dateTo
  }
}

function convertTextFilter(
  filter: any
): SingleFilterField | CompositeFilterField {
  if (filter.operator) {
    return {
      filterType: 'text',
      operator: filter.operator,
      condition1: {
        filterType: 'text',
        type: FILTER_TYPE_MAP[filter.type] || filter.type,
        filter: filter.condition1.filter
      },
      condition2: {
        filterType: 'text',
        type: FILTER_TYPE_MAP[filter.type] || filter.type,
        filter: filter.condition2.filter
      }
    }
  } else {
    return {
      filterType: 'text',
      type: FILTER_TYPE_MAP[filter.type] || filter.type,
      filter: filter.filter
    }
  }
}

function convertSelectFilter(filter: any): SingleFilterField {
  console.log('filter:', filter)
  if (filter.isAllButBlanks) {
    console.log('isAllButBlanks:', filter)
    return {
      filterType: 'select',
      type: 'notEmpty',
      filter: null,
      isSelectMultipleOptions: filter.isSelectMultipleOptions || false
    }
  }
  const isempty = filter.selectedOptions?.includes(null)
  if (isempty && filter.selectedOptions.length === 1) {
    return {
      filterType: 'select',
      type: 'empty',
      filter: filter.selectedOptions || filter.filter || [],
      isSelectMultipleOptions: filter.isSelectMultipleOptions || false
    }
  }

  return {
    filterType: 'select',
    type: 'in',
    filter: filter.selectedOptions || filter.filter || [],
    isSelectMultipleOptions: filter.isSelectMultipleOptions || false
  }
}

function convertFilterFieldsToV1(
  filterFields: Record<string, FilterField>
): Record<string, any> {
  const filterModel: Record<string, any> = {}

  for (const [key, value] of Object.entries(filterFields)) {
    if (isSingleFilterField(value)) {
      if (value.filterType === 'select') {
        filterModel[key] = {
          filterType: 'options',
          selectedOptions: Array.isArray(value.filter) ? value.filter : [],
          isAllButBlanks: false,
          isSelectMultipleOptions: false
        }
      } else if (value.filterType === 'date') {
        filterModel[key] = {
          filterType: 'date',
          selectedSearch: value.type,
          preDefinedValue: Array.isArray(value.filter)
            ? 'custom'
            : (value.filter as string),
          dateFrom: Array.isArray(value.filter) ? value.filter[0] : undefined,
          dateTo: Array.isArray(value.filter) ? value.filter[1] : undefined
        }
      } else {
        filterModel[key] = {
          filterType: value.filterType,
          type: value.type,
          filter: isValidFilterValue(value.filter) ? value.filter : null
        }
      }
    } else if (isCompositeFilterField(value)) {
      filterModel[key] = {
        filterType: value.filterType,
        operator: value.operator,
        condition1: convertSingleFilterFieldToV1(value.condition1),
        condition2: convertSingleFilterFieldToV1(value.condition2)
      }
    }
  }

  return filterModel
}

function convertSingleFilterFieldToV1(filter: SingleFilterField): any {
  return {
    filterType: filter.filterType,
    type: filter.type,
    filter: isValidFilterValue(filter.filter) ? filter.filter : null
  }
}

function getFilterTypeForV1(condition: string): string {
  if (
    condition.includes('contains') ||
    condition.includes('equals') ||
    condition.includes('starts_with') ||
    condition.includes('ends_with')
  ) {
    return 'agTextColumnFilter'
  } else if (
    condition.includes('greater_than') ||
    condition.includes('less_than')
  ) {
    return 'agNumberColumnFilter'
  } else if (condition === 'is_empty' || condition === 'is_not_empty') {
    return 'aiSetColumnFilter'
  } else {
    return 'agTextColumnFilter'
  }
}

function convertConditionalFormattingToV2(
  oldRules: any[]
): ConditionalFormatRule[] {
  const newRules: ConditionalFormatRule[] = []

  oldRules.forEach(rule => {
    rule.conditions.forEach(condition => {
      newRules.push({
        id: condition.id || String(Date.now()),
        column: rule.colId,
        target: 'cell',
        condition: mapConditionToV2(condition.filter) || '',
        value:
          condition.value ||
          condition.filterNum1 ||
          condition.filterNum2 ||
          condition.filter,
        color: condition.color || ''
      })
    })
  })

  return newRules
}

function convertConditionalFormattingToV1(
  rules: ConditionalFormatRule[]
): any[] {
  const v1Format: { [key: string]: any } = {}

  rules.forEach(rule => {
    if (rule.target === 'cell') {
      if (!v1Format[rule.column]) {
        v1Format[rule.column] = {
          colId: rule.column,
          conditions: []
        }
      }

      v1Format[rule.column].conditions.push({
        filterType: getFilterTypeForV1(rule.condition),
        filter: mapConditionToV1(rule.condition),
        color: rule.color,
        textColor: rule.textColor || '#000000',
        filterNum1: '',
        filterNum2: null,
        value: rule.value,
        id: rule.id
      })
    }
  })

  return Object.values(v1Format)
}

function mapConditionToV2(v1Condition: string): string {
  const conditionMap: { [key: string]: string } = {
    Contains: 'contains',
    'Not Contains': 'not_contains',
    Equals: 'equals',
    'Not Equal': 'not_equals',
    'Starts With': 'starts_with',
    'Ends With': 'ends_with',
    'Is Empty': 'is_empty',
    'Is Not Empty': 'is_not_empty',
    'Greater Than': 'greater_than',
    'greater then': 'greater_than',
    'Greater Than Or Equal': 'greater_than_or_equal',
    'Less Than': 'less_than',
    'Less Than Or Equal': 'less_than_or_equal',
    Between: 'between',
    In: 'in',
    From: 'after',
    Blank: 'is_empty',
    'Not Blank': 'is_not_empty'
  }
  // @ts-ignore
  if (v1Condition.includes(false)) {
    return 'is_empty'
  }
  // @ts-ignore
  if (v1Condition.includes(true)) {
    return 'is_not_empty'
  }

  if (Array.isArray(v1Condition)) {
    return 'equals'
  }
  if (
    typeof conditionMap[v1Condition] === 'undefined' &&
    typeof v1Condition === 'string'
  ) {
    return v1Condition.toLowerCase().replace(/\s/g, '_')
  }
  return conditionMap[v1Condition] || v1Condition.toLowerCase()
}

function mapConditionToV1(v2Condition: string): string {
  const conditionMap: { [key: string]: string } = {
    contains: 'Contains',
    not_contains: 'Not Contains',
    equals: 'Equals',
    not_equals: 'Not Equal',
    starts_with: 'Starts With',
    ends_with: 'Ends With',
    // @ts-ignore
    is_empty: [false],
    // @ts-ignore
    is_not_empty: [true]
  }

  return (
    conditionMap[v2Condition] ||
    v2Condition.charAt(0).toUpperCase() + v2Condition.slice(1)
  )
}

function convertSortModelToV2(columnState) {
  return columnState
    .map(column => {
      return {
        colId: column.colId,
        sort: column.sort
      }
    })
    .filter(column => column.sort)
}

export function adaptGridViewData(
  gridViewData: GridView['data'],
  toVersion2: boolean
): any {
  const data = gridViewData as GridViewData
  if (toVersion2 && isVersion1Data(data)) {
    console.log('Convert to version 2')
    return {
      ...data,
      filterModel: convertFilterModelToV2(data.filterModel),
      conditionalFormatting: convertConditionalFormattingToV2(
        data.conditionalFormatting || []
      ),
      sortModel: convertSortModelToV2(data.columnState)
    }
  } else if (!toVersion2 && !isVersion1Data(data)) {
    const filters = data.filterModel as Record<string, FilterField>
    const conditions = data.conditionalFormatting || []
    const filterModel = convertFilterFieldsToV1(filters)
    const conditionalFormatting = convertConditionalFormattingToV1(conditions)

    return {
      ...data,
      filterModel,
      conditionalFormatting
    }
  }

  return gridViewData
}
